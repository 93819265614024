import { HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs';
import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: "root",
  })
export class BaseService{

    public HOST: string = environment.backendUrl;
    public token : string = "";


    private subjectName = new Subject<any>(); //need to create a subject
    
    sendUpdate(message: string) { //the component that wants to update something, calls this fn
            this.subjectName.next({ text: message }); //next() will feed the value in Subject
    }
    
    getUpdate(): Observable<any> { //the receiver component calls this function 
            return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
    }

    

// METODOS PARA IMPLEMENTACION NUEVA

    public createAuthorizationHeader(): HttpHeaders {
        if(sessionStorage.getItem('user') != null){
            return new HttpHeaders({
                'Authorization': sessionStorage.getItem('token'),
                'User': sessionStorage.getItem('user'),
                'Access-Control-Allow-Origin': '*'
              });
        }else{
            return new HttpHeaders({
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Origin': '*'
              });
        }
       
    }

    public createLoginHeader(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
          });
    }

    public extractData(res: any) {
		return res.result;
	}

	public handleError (error: HttpErrorResponse | any) {
        console.log("ERROR")
        console.log(error);
		if (error instanceof Error) {
			// A client-side or network error occurred. Handle it accordingly.
			console.log('An error occurred: ', error.message ? error.message : error.toString());
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
            if(error.error != undefined){
                if(error.error.result != undefined){
                    if(error.error.result.responseCodeDescription != null){
                        return throwError(error.error.result.responseCodeDescription);
                    }else{
                        return throwError(error.error.message);
                    }
                }

                console.log(`Backend returned code: ${error.status} - ${error.statusText}, body was: ${error.message}`);
                return throwError(error.error.message);
            }else{

                return throwError(error);
            }
            
		}
		console.log('Raw error:', error);
		return throwError(error);
    }

// METODOS PARA IMPLEMENTACION NUEVA

    public extractDataFull(res: Response) {
        return res.json();
    }

    public extractHeaders(res: Response) {
        return res.headers;
    }

    public buildRequestParams(sort?: string, collection?: string, pager?: {pageIndex: number, pageSize: number}){
        
        let params = new HttpParams() ;

        if(sort != undefined){
            params = params.set('sort', sort);
        }
        if(collection != undefined){
            params = params.set('collection', collection);
        }

        if(pager != undefined){
            params = params.set('index', pager.pageIndex.toString());
            params= params.set('size', pager.pageSize.toString());
        }
        
        return params;
    }

    public static builderObject(json: {}): {}{
        let obj = {};
        for(var key in json){
            let innerObj = obj;
            let keySplits = key.split('.');

            for(var i = 0; i < keySplits.length-1; ++i){
                let keySplit = keySplits[i];
                if( !innerObj[keySplit] ) {
                    innerObj[keySplit] = {};
                }
                innerObj = innerObj[keySplit];
            }

            innerObj[keySplits[keySplits.length-1]] = (json[key] == '') ? null : json[key];
        }
        return obj;
    }

    public handleResponse(res: Response) {
        return res.ok;
    }

    /*

    public extractDataAndHeaders(res: Response) {
        let body = res.json();
        return { data: body.result || { }, headers: res.headers };
    }

    public handleResponse(res: Response) {
        return res.ok;
    }

    public buildRequestOptionsFinder(sort?: string, collection?: string, filter?: {}, pager?: {pageIndex: number, pageSize: number}): RequestOptions{
        let params: URLSearchParams = new URLSearchParams();

        if(sort != undefined){
            params.set('sort', sort);
        }
        if(collection != undefined){
            params.set('collection', collection);
        }
        for (var pos in filter){
            params.set(filter[pos]['param'], filter[pos]['value'].toString());
        }
        if(pager != undefined){
            params.set('index', pager.pageIndex.toString());
            params.set('size', pager.pageSize.toString());
        }

        let requestOptions = new RequestOptions();
        requestOptions.search = params;

        return requestOptions;
    }

    

    public handleError (error: Response | any) {
     console.log(error);
        let errMsg: string;
        if (error instanceof Response) {
          if (error.status === 403) {
            return observableThrowError('No posee permisos suficientes para esta accion, contacte con el administrador');
          }
            const body = error.json() || '';
            const err = body.error || JSON.stringify(body);
            // errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
            errMsg = JSON.parse(JSON.stringify({status: error.status, body: body}));
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
       /* console.error(errMsg);
        console.log(errMsg['body']['error']);
        if (errMsg['status'] === 400 && errMsg['body']['error'] === 'invalid_request') {
          this.notificationService.error('Su usuario no posee permisos para esta accion');
        } */
    //    return observableThrowError(errMsg);
   // }

    //http://stackoverflow.com/questions/18936915/dynamically-set-property-of-nested-object

    /* 

    public removeItem(items: any[], id: number){
        for(let i = 0; items.length; ++i)
            if(items[i].id == id){
                items.splice(i, 1);
                return;
            }
    }

    public removeItems(items: any[], ids: number[]){
        ids.map(id => this.removeItem(items, id));
    }

   public handleErrorCreate (error: Response | any) {
        if (error instanceof Response) {
            if(error.status == 400)
                return observableThrowError(error.json().message);
            if(error.status == 409)
                return observableThrowError(error.json().message);
        }
        return observableThrowError(error.json().message);
    }

     public static handleErrorUpdate (error: Response | any) {
         console.log(error);
        if (error instanceof Response) {
            if(error.status == 400)
                return observableThrowError(error.json().message);
            if(error.status == 404)
                return observableThrowError(error.json().message);
            if(error.status == 409)
                return observableThrowError(error.json().message);
        }
        return observableThrowError(error.json().message);
    }

    public static handleErrorDelete (error: Response | any) {
        if (error instanceof Response) {
          if (error.status === 403) {
            return observableThrowError('No posee permisos suficientes para esta accion, contacte con el administrador');
          }
          if (error.status === 409) {
            return observableThrowError(error.json().message);
          }
        }
        return observableThrowError(error.json().message);
    } */
}
